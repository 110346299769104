/* istanbul ignore file */

import { branchMasterDetailTables } from './branchMasterDetailTables';
import { usersMasterDetailTables } from './usersMasterDetailTables';
import { collectionMasterDetailTables } from './collectionMasterDetailTables';
import { shipmentMasterDetailTables } from './shipmentMasterDetailTables';
import { zoneMasterDetailTables } from './zoneMasterDetailTables';
import { deliveryMasterDetailTables } from './deliveryMasterDetailTables';

export const masterDetailsTablesProps = {
  branch_customer_kyc: {
    title: 'Customer KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&partner_type__customer=True&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Customer KYC',
        url: '/settings/branch_kyc_documents/customer/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_customer_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  branch_supplier_kyc: {
    title: 'Supplier KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&partner_type__customer=False&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Supplier KYC',
        url: '/settings/branch_kyc_documents/supplier/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_supplier_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  branch_staff_kyc: {
    title: 'Staff KYC Documents',
    ajaxURL:
      '/api/kyc-documents/?format=json&staff_vetting=True&fields=id,name,staff_type_name,branch,',
    addURLs: [
      {
        name: 'Create Staff KYC',
        url: '/settings/branch_kyc_documents/staff/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'staff_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_staff_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  unit_of_measure: {
    title: 'Unit Of Measure',
    ajaxURL:
      '/api/unit-of-measure/?format=json&fields=id,name,category_type,uom_type,factor,system_generated',
    addURLs: [
      {
        name: 'Create Unit Of Measure',
        url: '/products/create_unit_of_measure',
      },
    ],
    exportURL: '/products/export_unit_of_measure',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Category', field: 'category_type' },
      { title: 'Type', field: 'uom_type' },
      { title: 'Factor', field: 'factor' },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/update_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/delete_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  skus: {
    title: 'Stock Keeping Units',
    ajaxURL:
      '/api/skus/?format=json&fields=id,name,description,can_be_sold,selling_price,can_be_bought,purchasing_price,system_generated',
    exportURL: '/',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Can Be Sold',
        field: 'can_be_sold',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Sales Price',
        formatter: function (cell) {
          if (cell.getRow().getData().can_be_sold === true) {
            return 'KES ' + cell.getRow().getData().selling_price;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Can Be Purchased',
        field: 'can_be_bought',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Purchase Price',
        formatter: function (cell) {
          if (cell.getRow().getData().can_be_bought === true) {
            return 'KES ' + cell.getRow().getData().purchasing_price;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/update_sku/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/delete_sku/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  ...branchMasterDetailTables,
  ...usersMasterDetailTables,
  ...collectionMasterDetailTables,
  ...shipmentMasterDetailTables,
  ...zoneMasterDetailTables,
  ...deliveryMasterDetailTables,
};
